import React, { useEffect, useRef, useState } from "react";
import { EmailInput } from "../../components/EmailInput";
import { PasswordInput } from "../../components/PasswordInput";
import { SignInUpButton } from "../../components/SignInUpButton";
import "./style.css";
import { PhoneNumberInput } from "../../components/PhoneNumberInput";
import validator from "validator";
import { TextInput } from "../../components/TextInput";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SuccessMessage } from "../../components/SuccessMessage/SuccessMessage";
import { CheckBox } from "../../components/CheckBox/CheckBox";
import { SelectBox } from "../../components/SelectBox/SelectBox";
import { DateInput } from "../../components/DateInput/DateInput";

export const SignInFlow = ({ setLoadingFlag, type, regFlag }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [gender, setGender] = useState();
  const [date, setDate] = useState();
  const [confPassword, setConfPassword] = useState();
  const [errors, setErrors] = useState({});
  const [currentType, setCurrentType] = useState("patient");
  const [welcomeFlag, setWelcomeFlag] = useState(false);
  const [isAgreed, setIsAgreed] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ref = useRef();

  function onSubmit() {
    let errorsObj = {};
    setLoadingFlag(true);
    if (email && !validator.isEmail(email)) {
      errorsObj.email = t("email.error");
    }

    if (phoneNumber && !validator.isMobilePhone(phoneNumber)) {
      errorsObj.phone = t("phone.validation.error");
    }

    if (password.length < 6) {
      errorsObj.password = t("password.error");
    }

    if (regFlag && password !== confPassword) {
      errorsObj.confPassword = t("password.confirmation.error");
    }

    let data = {
      identifier: phoneNumber,
      username: phoneNumber,
      firstname: firstName,
      lastname: lastName,
      password: password,
      type: currentType,
      applicationLanguage: "en",
      birthdate: date,
      gender,
    };

    if (!Object.keys(errorsObj).length) {
      const result = regFlag
        ? AuthService.register(data, navigate, setLoadingFlag, setWelcomeFlag)
        : AuthService.login(data, navigate, setLoadingFlag);

      result.catch((error) => {
        setLoadingFlag(false);
        if (error?.message === "Network Error") {
          errorsObj.auth = "Network Error";
        } else {
          errorsObj.auth = t(error?.response?.data?.error?.message);
        }
        setErrors(errorsObj);
      });
    } else {
      setLoadingFlag(false);
    }

    setErrors(errorsObj);
  }

  function forgotPassword() {
    let errorsObj = {};
    let data = {
      identifier: phoneNumber,
      type: currentType,
    };

    if (phoneNumber && !validator.isMobilePhone(phoneNumber)) {
      errorsObj.phone = t("phone.validation.error");
    } else if (!phoneNumber) {
      errorsObj.phone = t("phone.required.error");
    } else {
      AuthService.forgetPass(data, setLoadingFlag);
    }
    setErrors(errorsObj);
  }

  useEffect(() => {
    if (type) {
      setCurrentType(type);
    }
  }, [type]);

  return (
    <>
      {welcomeFlag ? (
        <SuccessMessage
          message={t("auth.successreg")}
          setLoadingFlag={setLoadingFlag}
        />
      ) : (
        <div className="sign-in-flow d-flex">
          <div className="overlap-group-wrapper d-flex">
            <div className="overlap-group d-flex flex-wrap">
              <div className="rectangle d-flex flex-column">
                <a href="/">
                  <img  loading="lazy" className="logo" alt="Logo" src="/img/logo.svg" />
                </a>
                <div className="my-auto img-container">
                  <img  loading="lazy"
                    className="illustration d-flex"
                    alt="Illustration"
                    src="/img/illustration.svg"
                  />
                </div>
                <div className="mt-auto">
                  <div className="text-wrapper-2 mb-3">
                    {t("auth.welcometitle")}
                  </div>
                  <div className="fit-content mx-auto">
                    <p className="doxx-is-an mb-5 text-start pl-2">
                      {t("auth.welcomecontent1")}
                      <br />
                      {t("auth.welcomecontent2")}
                      <br />
                      {t("auth.welcomecontent3")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="group">
                <p className="p mb-3">
                  {regFlag ? t("auth.createaccount") : t("auth.signintitle")}
                </p>
                {regFlag ? (
                  <>
                    <TextInput
                      property1="empty"
                      placeholder={t("auth.firstname")}
                      onChange={setFirstName}
                      error={errors?.firstName}
                      divClass="mb-3"
                    />
                    <TextInput
                      property1="empty"
                      placeholder={t("auth.lastname")}
                      onChange={setLastName}
                      error={errors?.lastName}
                      divClass="mb-3"
                    />
                    <SelectBox
                      items={[
                        {
                          name: "male",
                        },
                        { name: "female" },
                      ]}
                      placeholder={t("select.gender")}
                      onChange={setGender}
                      divClass="mb-3"
                      formClass="select-login email-input"
                      value={gender}
                    />
                    <DateInput
                      property1="empty"
                      placeholder={t("select.birthdate")}
                      onChange={setDate}
                      error={errors?.date}
                      divClass="mb-3 "
                      value={date}
                      maxDate={new Date()?.toISOString()?.split("T")[0]}
                    />
                  </>
                ) : (
                  ""
                )}
                <PhoneNumberInput
                  className="email-input-instance"
                  property1="empty"
                  onChange={setPhoneNumber}
                  error={errors?.phone}
                  placeholder={t("auth.enterphone")}
                />
                {regFlag ? (
                  <EmailInput
                    className="email-input-instance"
                    property1="empty"
                    placeholder={t("auth.email")}
                    onChange={setEmail}
                    error={errors?.email}
                  />
                ) : (
                  ""
                )}
                <PasswordInput
                  className="password-input-instance"
                  property1="empty"
                  placeholder={t("auth.password")}
                  onChange={setPassword}
                  error={errors?.password}
                  divClass={regFlag ? 'mb-3' : ''}
                />
                {regFlag ? (
                  <>
                    <PasswordInput
                      className="conf-password-input-instance"
                      property1="empty"
                      placeholder={t("auth.confpass")}
                      onChange={setConfPassword}
                      error={errors?.confPassword}
                      divClass={'mb-3'}
                    />
                    <div className="margin-top2rem">
                      <CheckBox
                        label={`${t("terms.agree")}`}
                        onChange={setIsAgreed}
                        selected={isAgreed}
                        value="checked"
                        privacyPolicy={true}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="position-relative">
                  <p className="text-danger h6 position-absolute text-start">
                    {errors?.auth}
                  </p>
                </div>
                <SignInUpButton
                  className="sign-in-up-button-instance my-4 sign-in-up-button "
                  onClick={onSubmit}
                  state={
                    (regFlag &&
                      phoneNumber &&
                      firstName &&
                      password &&
                      confPassword &&
                      gender &&
                      date &&
                      isAgreed) ||
                    (!regFlag && phoneNumber && password)
                      ? "default"
                      : "greyed-out"
                  }
                  name={regFlag ? t("auth.signup") : t("auth.signin")}
                />
                {!regFlag ? (
                  <div
                    className="text-wrapper-3 mb-3 d-block pointer"
                    onClick={() => forgotPassword()}
                  >
                    {t("auth.forgotpass")}
                  </div>
                ) : (
                  ""
                )}
                <div className="frame mt-2">
                  <p className="text-wrapper-4 mb-0">
                    {regFlag
                      ? t("auth.alreadyregistered")
                      : t("auth.nothaveaccount")}
                  </p>
                  <a
                    href={regFlag ? "/login" : "/register"}
                    className="text-wrapper-5"
                  >
                    {regFlag ? t("auth.signin") : t("auth.signup") + "!"}
                  </a>
                </div>
                <p
                  className="m-auto type-ask pointer mb-3"
                  onClick={() => {
                    currentType === "doctor"
                      ? setCurrentType(
                          currentType === "patient" ? "doctor" : "patient"
                        )
                      : (window.location.pathname = "/doctor-registration");
                  }}
                >
                  {t("auth.areyou")}{" "}
                  {currentType === "patient"
                    ? t("auth.doctor") + "?"
                    : t("auth.patient") + "?"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
