import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { SignInFlow } from "./screens/SignInFlow";
import { RegistrationFlow } from "./screens/RegistrationFlow/RegistrationFlow";
import "./i18n";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import LandingPage from "./screens/LandingPage/LandingPage";
import { useEffect, useState } from "react";
import { Loading } from "./components/Loading";
import { useTranslation } from "react-i18next";
import Clinic from "./screens/Clinic/Clinic";
import TeleHealth from "./screens/TeleHealth/TeleHealth";
import HomeVisit from "./screens/HomeVisit/HomeVisit";
import Cart from "./screens/Cart/Cart";
import TokenService from "./services/token.service";
import { Verification } from "./screens/Verification/Verification";
import Dashboard from "./screens/Dashboard/Dashboard";
import FamilySubscription from "./screens/FamilySubscription/FamilySubscription";
import PersonalSubscription from "./screens/PersonalSubscription/PersonalSubscription";
import Logout from "./components/Logout";
import BookingPayment from "./screens/BookingPayment/BookingPayment";
import Pharmacy from "./screens/Pharmacy/pharmacy";
import PharmacyList from "./screens/PharmacyList/PharmacyList";
import ScanLab from "./screens/ScanLab/ScanLab";
import Nurses from "./screens/Nurses/Nurses";
import ScanLabList from "./screens/ScanLabList/ScanLabList";
import NursesList from "./screens/NursesList/NursesList";
import { ToastContainer } from "react-toastify";
import { DoctorRegistration } from "./screens/DoctorRegistration/DoctorRegistration";
import BookingService from "./services/booking.service";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { ErrorBoundary } from "react-error-boundary";
import { NotFound } from "./components/NotFound/NotFound";
import { ErrorPage } from "./components/ErrorPage/ErrorPage";
import GettingService from "./services/getting.service";
import { getCountryIp } from "./helpers/getCountryIP";
import PusherEvent from "./components/PusherEvent/PusherEvent";
import { Login } from "./screens/Seo/Login";
import { SeoForm } from "./screens/Seo/SeoForm";
import { SeoTable } from "./screens/Seo/SeoTable";
import { BlogTable } from "./screens/Blog/BlogTable";
import { WebsiteBlogs } from "./screens/Blog/WebsiteBlogs";
import { Doctor } from "./screens/Doctor/Doctor";
import { initGA, logPageView } from './helpers/analytics';
const TRACKING_ID =  !process.env.NODE_ENV ||
process.env.NODE_ENV === "development" ||
(window.location.host !== "doxx.care" &&
  window.location.host !== "www.doxx.care")
  ? "" : 'G-WL8J22PSQW';

function App() {
  const [loadingFlag, setLoadingFlag] = useState(false);
  const { i18n } = useTranslation();
  const [savedValue, setSavedValue] = useState();

  const TrackLocation = () => {
    const location = useLocation();
  
    useEffect(() => {
      logPageView();
    }, [location]);

    return null;
  };

  useEffect(() => {
    initGA(TRACKING_ID);
    logPageView();
    window.addEventListener('popstate', logPageView);
  }, []);

  useEffect(() => {
    if (TokenService.getUser() && TokenService.getUser()?.confirmed) {
      GettingService?.getSavingsValue(setSavedValue, setLoadingFlag).catch(
        () => {}
      );
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const reference = localStorage.getItem("order_reference");
    if (reference) {
      BookingService?.checkPayment(reference);
    }
    getCountryIp();
  }, []);

  return (
    <div className="App" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <PusherEvent />
      <ToastContainer />
      <Loading display={loadingFlag ? "d-flex" : "d-none"} />
      <ErrorBoundary fallback={<ErrorPage />}>
        <BrowserRouter>
          <Header
            setLoadingFlag={setLoadingFlag}
            savedValue={savedValue}
            display={
              window.location.pathname !== "/login" &&
              window.location.pathname !== "/verify" &&
              window.location.pathname !== "/register" &&
              window.location.pathname !== "/cart" &&
              window.location.pathname !== "/dashboard" &&
              window.location.pathname !== "/clinic-booking" &&
              window.location.pathname !== "/tele-health-booking" &&
              window.location.pathname !== "/home-visit-booking" &&
              window.location.pathname !== "/seo-login" &&
              window.location.pathname !== "/seo-form" &&
              window.location.pathname !== "/seo-screens" &&
              window.location.pathname !== "/seo-blogs"
                ? ""
                : "d-none"
            }
          />
          <TrackLocation />
          <Routes>
            <Route
              exact
              path="/"
              element={<LandingPage setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/doctor-registration"
              element={<DoctorRegistration setLoadingFlag={setLoadingFlag} />}
            />
             <Route
              exact
              path="/doctor/:id"
              element={<Doctor setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/clinics"
              element={<Clinic setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/tele-health"
              element={<TeleHealth setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/home-visit"
              element={<HomeVisit setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/login"
              element={
                !TokenService.getUser() ? (
                  <SignInFlow setLoadingFlag={setLoadingFlag} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              exact
              path="/seo-login"
              element={
                !TokenService.getUser(true) ? (
                  <Login setLoadingFlag={setLoadingFlag} />
                ) : (
                  <Navigate to="/seo-screens" />
                )
              }
            />
            <Route
              exact
              path="/seo-screens"
              element={
                TokenService.getUser(true) ? (
                  <SeoTable setLoadingFlag={setLoadingFlag} />
                ) : (
                  <Navigate to="/seo-login" />
                )
              }
            />
             <Route
              exact
              path="/seo-blogs"
              element={
                TokenService.getUser(true) ? (
                  <BlogTable setLoadingFlag={setLoadingFlag} />
                ) : (
                  <Navigate to="/seo-login" />
                )
              }
            />
            <Route
              exact
              path="/logout"
              element={
                TokenService.getUser() ? <Logout /> : <Navigate to="/" />
              }
            />
            {/* <Route
              exact
              path="/verify"
              element={!TokenService.getUser() ? <SignInFlow setLoadingFlag={setLoadingFlag}/> : TokenService.getUser().confirmed === true ? <Navigate to="/" /> : ''}
            /> */}
            <Route
              exact
              path="/pharmacy"
              element={<Pharmacy setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/pharmacy-details"
              element={<PharmacyList setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/lab-scan"
              element={<ScanLab setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/scan-details"
              element={
                <ScanLabList setLoadingFlag={setLoadingFlag} type={"scan"} />
              }
            />
            <Route
              exact
              path="/lab-details"
              element={
                <ScanLabList setLoadingFlag={setLoadingFlag} type={"lab"} />
              }
            />
            <Route
              exact
              path="/blogs"
              element={
                <WebsiteBlogs setLoadingFlag={setLoadingFlag} type={"lab"} />
              }
            />
            {/* <Route
              exact
              path="/nursing"
              element={<NursesList setLoadingFlag={setLoadingFlag} />}
            /> */}
            <Route
              exact
              path="/nursing-details"
              element={<NursesList setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/verify"
              element={<Verification setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/dashboard"
              element={
                !TokenService.getUser() ? (
                  <Navigate to="/login" />
                ) : (
                  <Dashboard setLoadingFlag={setLoadingFlag} />
                )
              }
            />
            <Route
              exact
              path="/family-plans"
              element={<FamilySubscription setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/personal-plans"
              element={<PersonalSubscription setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/clinic-booking"
              element={
                !TokenService.getUser() ? (
                  <Navigate to="/login" />
                ) : (
                  <BookingPayment setLoadingFlag={setLoadingFlag} />
                )
              }
            />
            <Route
              exact
              path="/tele-health-booking"
              element={
                !TokenService.getUser() ? (
                  <Navigate to="/login" />
                ) : (
                  <BookingPayment setLoadingFlag={setLoadingFlag} />
                )
              }
            />
            <Route
              exact
              path="/home-visit-booking"
              element={
                !TokenService.getUser() ? (
                  <Navigate to="/login" />
                ) : (
                  <BookingPayment setLoadingFlag={setLoadingFlag} />
                )
              }
            />
            <Route
              exact
              path="/register"
              element={<RegistrationFlow setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="/cart"
              element={
                !TokenService.getUser() ? (
                  <Navigate to="/login" />
                ) : (
                  <Cart setLoadingFlag={setLoadingFlag} />
                )
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicy setLoadingFlag={setLoadingFlag} />}
            />
            <Route
              exact
              path="*"
              element={<NotFound setLoadingFlag={setLoadingFlag} />}
            />
          </Routes>
          <Footer
            display={
              window.location.pathname !== "/login" &&
              window.location.pathname !== "/verify" &&
              window.location.pathname !== "/register" &&
              window.location.pathname !== "/cart" &&
              window.location.pathname !== "/dashboard" &&
              window.location.pathname !== "/clinic-booking" &&
              window.location.pathname !== "/tele-health-booking" &&
              window.location.pathname !== "/home-visit-booking" &&
              window.location.pathname !== "/seo-login" &&
              window.location.pathname !== "/seo-form" &&
              window.location.pathname !== "/seo-screens" &&
              window.location.pathname !== "/seo-blogs"
                ? ""
                : "d-none"
            }
          />
          <Tooltip id="my-tooltip" />
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
