import { useLocation, useNavigate } from "react-router-dom";
import { DashboardBanners } from "../../components/DashboardBanners/DashboardBanners";
import "./style.css";
import TokenService from "../../services/token.service";
import { DashboardMenuItem } from "../../components/DahboardMenuItem/DashboardMenuItem";
import { useEffect, useState } from "react";
import { deleteData, getData } from "../../services/apiService";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { AddQuestion } from "../../components/AddQuestion/AddQuestion";
import { SettingsTab } from "../../components/SettingsTab/SettingsTab";
import { BookingTab } from "../../components/BookingTab/BookingTab";
import { Notifications } from "../../components/Notifications/Notifications";
import { confirmAlert } from "react-confirm-alert";
import AuthService from "../../services/auth.service";
import SubscriptionTab from "../../components/SubscriptionTab/SubscriptionTab";
import UserDetailsLayout from "../../components/UserDetailsLayout/UserDetailsLayout";
import { MedicalQuestions } from "../../components/MedicalQuestions/MedicalQuestions";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import { FamilyHistory } from "../../components/FamilyHistroy/FamilyHistory";
import { LoyaltyPoints } from "../../components/LoyaltyPoints/LoyaltyPoints";

const Dashboard = ({ setLoadingFlag }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { i18n, t } = useTranslation();
  const user = TokenService.getUser();
  const [activeTab, setActiveTab] = useState(
    state?.selected ? state?.selected : "overview"
  );
  const [orderTab, setOrderTab] = useState("");
  const [medicalQuestions, setMedicalQuestions] = useState([]);
  const [offset, setOffset] = useState(1);
  const [modalQuestion, setModalQuestion] = useState(
    state?.selected === "medical" ? true : false
  );
  const [notificationDisplay, setNotificationDisplay] = useState("d-none");
  const [detailedQuestion, setDetailedQuestion] = useState({});
  const [DashboardTitle, setDashboardTitle] = useState(
    t("dashboard.welcomeback")
  );
  const [editQuestion, setEditQuestion] = useState("");
  const [userData, setUserData] = useState({});
  const [addresses, setAddresses] = useState([]);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  Moment.locale(i18n.language);

  const getQuestions = async () => {
    setLoadingFlag(true);
    const user = TokenService.getUser();
    await getData(
      "/medical-questions",
      {
        "filters[patient][id]": user?.patient_id,
        "populate":"attachments"
      },
      true
    )
      .then((response) => {
        setMedicalQuestions(response.data);
        setLoadingFlag(false);
      })
      .catch((error) => setLoadingFlag(false));
  };

  function addQuestion(item, editFlag) {
    const arr = medicalQuestions;
    if (editFlag) {
      arr = medicalQuestions.filter((q) => q.id === item.id);
      arr[0].attributes.explain = item.explain;
      setMedicalQuestions(arr);
    } else {
      arr.unshift({ attributes: item, id: item?.id });
      setMedicalQuestions(arr);
    }
  }

  function notificationClick() {
    if (notificationDisplay === "d-none") {
      setNotificationDisplay("d-flex");
    } else {
      setNotificationDisplay("d-none");
    }
  }

  function logout() {
    confirmAlert({
      title: "",
      message: t("logout.warning"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            navigate("/logout");
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }

  async function getUser() {
    await AuthService.getUserData(
      null,
      null,
      setUserData,
      setAddresses,
      setLoadingFlag
    );
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    getUser();
    if (activeTab === "medical") {
      getQuestions();
    }
  }, [activeTab]);

  useEffect(() => {
    if (query.get("selected") === "booking") {
      setActiveTab("booking");
      setOrderTab("appointment");
    } else if (query.get("selected") === "order") {
      setActiveTab("booking");
      setOrderTab("orders");
    } else if (query.get("selected") === "subscription") {
      setActiveTab("subscription");
    }
  }, []);

  return (
    <>
    <MetaTags title={`${t('doxx')} - ${t('dashboard')}`} description={t("dashboard.forpatient")} />
    <div className="dashboard-patients-overview d-flex">
      <div className="full-side-menu">
        <div className="title1">
          <div className="title2">
            <img  loading="lazy" className="title-child" alt="" src="./img/frame-23@2x.png" />
            <div className="dashboard-parent">
              <div className="dr-hamdy-maged">{t("dashboard")}</div>
              <i className="for-patients">{t("dashboard.forpatient")}</i>
            </div>
          </div>
        </div>
        <div className="main-menu">
          <DashboardMenuItem
            title={t("dashboard.overview")}
            imgSrc={"./img/overview.svg"}
            type="overview"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("dashboard.welcomeback")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/preview@2x.png"}
          />
          <DashboardMenuItem
            title={t("loaylityPointsDetails")}
            imgSrc={"./img/card_13430372.png"}
            type="loyaltyPoints"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("loaylityPointsDetails")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/card_13367647.png"}
          />
          <DashboardMenuItem
            title={t("medicalReports")}
            imgSrc={"./img/analysis-graph.png"}
            type="medicalReports"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("medicalReports")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/analysis-graph (1).png"}
          />
          <DashboardMenuItem
            title={t("myordershistory")}
            imgSrc={"./img/pending-actions@2x.png"}
            type="booking"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("myordershistory")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/active-booking.svg"}
          />
          <DashboardMenuItem
            title={t("dashboard.medicalquestions")}
            imgSrc={"./img/medical-question@2x.png"}
            type="medical"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("dashboard.medicalquestiontitle")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/active-medical.svg"}
          />
          <DashboardMenuItem
            title={t("dashboard.myprofile")}
            imgSrc={"./img/settings@2x.png"}
            type="settings"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("profile.details")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/active-settings.svg"}
          />
           <DashboardMenuItem
            title={t("dashboard.familyhistory")}
            imgSrc={"./img/family_grey.png"}
            type="familyhistory"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("dashboard.familyhistory")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/family.png"}
          />
          <DashboardMenuItem
            title={t("dashboard.subscription")}
            imgSrc={"./img/stars-nonactive.svg"}
            type="subscription"
            activeTab={activeTab}
            onClick={setActiveTab}
            dashboardTitle={t("dashboard.yourplans")}
            setDashboardTitle={setDashboardTitle}
            activeImg={"./img/stars.svg"}
          />
          <div className="booking-activity pointer" onClick={() => logout()}>
            <img  loading="lazy"
              className="pending-actions-icon"
              alt=""
              src="./img/power_settings_new.svg"
            />
            <div className="dr-hamdy-maged">{t("logout")}</div>
          </div>
        </div>
        <div className="supportreturn">
          <div
            className="booking-activity pointer"
            onClick={() => (window.location.pathname = "/")}
          >
            <img  loading="lazy"
              className="pending-actions-icon"
              alt="arrow"
              src="./img/keyboard-return@2x.png"
            />
            <div className="return-home-dash">{t("dashboard.returnhome")}</div>
          </div>
        </div>
      </div>
      <div
        className={`dashboard-container ${
          i18n.language === "en" ? "left25per" : "right25per"
        }`}
      >
        <div className="welcome-banner">
          <div className="welcome-message">
            <div className="hi-john-doe">{`${t("dashboard.hi")} ${
              user.firstname
            } ${user?.lastname}`}</div>
            <b className="welcome-back">{DashboardTitle}</b>
          </div>
          <div
            className={`d-flex gap-2 m${
              i18n.language === "ar" ? "e" : "s"
            }-auto`}
          >
            {activeTab === "medical" && Object.keys(detailedQuestion).length === 0 ? (
              <img  loading="lazy"
                className="add-circle-outline-icon new-question pointer"
                alt="add-circle"
                src="./img/add-circle-outline@2x.png"
                onClick={() => setModalQuestion(true)}
              />
            ) : (
              ""
            )}
            <div className="position-relative">
              <img  loading="lazy"
                className="notifications-icon pointer"
                alt="notification"
                src="./img/notifications@2x.png"
                onClick={() => notificationClick()}
              />
              <Notifications
                display={notificationDisplay}
                setLoadingFlag={setLoadingFlag}
              />
            </div>
          </div>
        </div>
        {activeTab === "overview" && (
          <div className="dashboard-home-container">
            <div className="feature-banner-carousel">
              <DashboardBanners />
            </div>
            <UserDetailsLayout user={userData} addresses={addresses} />
          </div>
        )}
        {activeTab === "medical" && (
          <>
            <AddQuestion
              modalOpen={modalQuestion}
              setModalOpen={setModalQuestion}
              AddQuestionToArray={addQuestion}
              questionItem={editQuestion}
              setQuestionItem={setEditQuestion}
              setLoadingFlag={setLoadingFlag}
            />
            <MedicalQuestions
              detailedQuestion={detailedQuestion}
              setDetailedQuestion={setDetailedQuestion}
              medicalQuestions={medicalQuestions}
              setMedicalQuestions={setMedicalQuestions}
              setLoadingFlag={setLoadingFlag}
            />
          </>
        )}
        {activeTab === "booking" && (
          <BookingTab
            setLoadingFlag={setLoadingFlag}
            orderTab={orderTab}
            userData={userData}
          />
        )}
        {activeTab === "settings" && (
          <SettingsTab
            setLoadingFlag={setLoadingFlag}
            userData={userData}
            addresses={addresses}
            setAddresses={setAddresses}
          />
        )}
        {activeTab === "subscription" && userData && (
          <SubscriptionTab
            plan={userData?.supscription_plan}
            setLoadingFlag={setLoadingFlag}
            userData={userData}
          />
        )}
        {activeTab === "familyhistory" && (
          <FamilyHistory setLoadingFlag={setLoadingFlag}/>
        )}
         {activeTab === "loyaltyPoints" && (
          <LoyaltyPoints  setLoadingFlag={setLoadingFlag}/>
)}
      </div>
    </div>
    </>
  );
};

export default Dashboard;
