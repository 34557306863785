import { useTranslation } from "react-i18next";
import "./LandingPage.css";
import { Slides } from "../../components/Slides";
import { useCallback, useEffect, useState } from "react";
import { getData } from "../../services/apiService";
import { Tab } from "../../components/Tab";
import { SelectBox } from "../../components/SelectBox/SelectBox";
import { TextInput } from "../../components/TextInput";
import { useNavigate } from "react-router";
import GettingService from "../../services/getting.service";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import Specialties from "../../components/Specialties/Specialties";

const LandingPage = ({ setLoadingFlag }) => {
  const { t, i18n } = useTranslation();
  const [offers, setOffers] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [regions, setRegions] = useState([]);
  const [active, setActive] = useState("doctor");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [searchName, setSearchName] = useState("");
  const [homeType, setHomeType] = useState("homevisit");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [categories, setCategories] = useState([]);
  const [LabSuppliers, setLabSuppliers] = useState([]);
  const [nursingSuppliers, setNursingSuppliers] = useState([]);

  const navigate = useNavigate();

  const getOffers = useCallback(async () => {
    await getData("/offers", {
      locale: i18n?.language ? i18n?.language : "en",
      "populate[image]": "*",
      "populate[supplier_product][populate][supplier]": "*",
    })
      .then((response) => {
        setOffers(response.data);
      })
      .catch((error) => {});
  }, [setOffers]);

  const getSpecialties = useCallback(async () => {
    setLoadingFlag(true);
    await getData("/specialities/doctors/count", {
      "filters[locale][$eq]": localStorage.getItem("lang"),
    })
      .then((response) => {
        setSpecialties(response);
      })
      .catch((error) => {});
  }, [setSpecialties]);

  const getRegions = useCallback(async () => {
    await getData("/cities", {
      locale: localStorage.getItem("lang"),
    })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {});
  }, [setRegions]);

  const getCategories = useCallback(async () => {
    await GettingService.getCategories(
      setCategories,
      "pharmacy",
      null,
      setLoadingFlag
    );
  }, [setLoadingFlag, setCategories]);

  const getNursingSuppliers = useCallback(async () => {
    await GettingService.getSuppliers(
      setNursingSuppliers,
      "nurse",
      setLoadingFlag
    );
  }, [setLoadingFlag, setNursingSuppliers]);

  const getLabSuppliers = useCallback(async () => {
    await GettingService.getSuppliers(setLabSuppliers, "lab", setLoadingFlag);
  }, [setLoadingFlag, setLabSuppliers]);

  function search() {
    switch (active) {
      case "doctor":
        navigate("clinics", {
          state: {
            searchWord: searchName,
            speciality: selectedSpecialty,
            city: selectedRegion,
          },
        });
        break;
      case "telehealth":
        navigate("tele-health", {
          state: {
            searchWord: searchName,
            speciality: selectedSpecialty,
          },
        });

        break;
      case "homevisit":
        if (homeType === "home-visit") {
          navigate("home-visit", {
            state: {
              searchWord: searchName,
              speciality: selectedSpecialty,
            },
          });
        } else {
          navigate("nursing-details", {
            state: {
              searchWord: searchName,
              provider: selectedProvider,
            },
          });
        }

        break;
      case "pharmacy":
        navigate("pharmacy-details", {
          state: {
            searchWord: searchName,
            category: selectedCategory,
          },
        });

        break;
      case "lab-scan":
        navigate("lab-scan-details", {
          state: {
            searchWord: searchName,
            provider: selectedProvider,
          },
        });

        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getOffers();
    getRegions();
    getSpecialties();
    getCategories();
    getNursingSuppliers();
    getLabSuppliers();
  }, [
    getOffers,
    getRegions,
    getSpecialties,
    getCategories,
    getNursingSuppliers,
    getLabSuppliers,
    i18n.language,
  ]);
  return (
    <>
      <MetaTags
        title={t("doxx")}
        description={t("doxx.slogan")}
        image={"https://doxx.care/img/logo@2x.png"}
        type={"Home page"}
      />
      <div className="landing-page-logged-out">
        {/* {TokenService?.getUser()  ? (
          <div className="savedDiv py-2">
            {`${t("youSaved")} ${19,232} ${t("egp")} ${t("withDoxx")}`}
          </div>
        ) : (
          ""
        )} */}
        <div className="main-frame">
          <div className="d-flex flex-column slogan-logo none-mobile-view">
            <img  loading="lazy"
              src="/img/Logo&Slogan (Colored).png"
              alt="slogan"
              className=""
            />
            <div className="slogan-text">{t("doxx.slogan")}</div>
          </div>
          <div className="orientationquickactions">
            <div className="quick-actions">
              <TextInput
                property1="empty"
                className="text-input width100per search-input none-web-view"
                placeholder={t("landing.searchname")}
                onChange={setSearchName}
                divClass=""
              />
              <div className="quick-actions-bar">
                <Tab
                  className="book-a-doctor position-relative"
                  imgSrc={
                    active === "doctor"
                      ? "/img/book-online@2x.png"
                      : "/img/book_online.svg"
                  }
                  text1={t("landing.bookdoctor")}
                  text2={t("landing.examorproceedure")}
                  active={active === "doctor" ? true : false}
                  onclick={() => {
                    setActive("doctor");
                    setSelectedRegion("");
                    setSearchName("");
                    setSelectedSpecialty("");
                  }}
                />
                <Tab
                  className="telehealth position-relative"
                  imgSrc={
                    active === "telehealth"
                      ? "/img/headset_mic-active.svg"
                      : "/img/headset-mic@2x.png"
                  }
                  text1={t("header.telehealth")}
                  text2={t("landing.callconsult")}
                  active={active === "telehealth" ? true : false}
                  onclick={() => {
                    setActive("telehealth");
                    setSelectedRegion("");
                    setSearchName("");
                    setSelectedSpecialty("");
                  }}
                />
                <Tab
                  className="telehealth position-relative"
                  imgSrc={
                    active === "homevisit"
                      ? "/img/home-active.svg"
                      : "/img/home@2x.png"
                  }
                  text1={t("header.homeservices")}
                  text2={t("landing.examnursing")}
                  active={active === "homevisit" ? true : false}
                  onclick={() => {
                    setActive("homevisit");
                    setSelectedRegion("");
                    setSearchName("");
                    setSelectedSpecialty("");
                  }}
                />
                <Tab
                  className="telehealth position-relative"
                  imgSrc={
                    active === "lab-scan"
                      ? "/img/lab-light.svg"
                      : "/img/lab-dark.svg"
                  }
                  text1={t("header.labscans")}
                  text2={t("scanlab.title")}
                  active={active === "lab-scan" ? true : false}
                  onclick={() => {
                    setActive("lab-scan");
                    setSelectedRegion("");
                    setSearchName("");
                    setSelectedSpecialty("");
                  }}
                />
                <Tab
                  className="home-visit position-relative"
                  imgSrc={
                    active === "pharmacy"
                      ? "/img/pharm-light.svg"
                      : "/img/pharm-dark.svg"
                  }
                  text1={t("header.pharmacy")}
                  text2={t("pharmacy.title")}
                  active={active === "pharmacy" ? true : false}
                  onclick={() => {
                    setActive("pharmacy");
                    setSelectedRegion("");
                    setSearchName("");
                    setSelectedSpecialty("");
                  }}
                />
              </div>
              <div className="booking-search-fields flex-wrap">
                {active === "homevisit" ? (
                  <SelectBox
                    placeholder={t("type")}
                    items={[{ name: "nursing" }, { name: "home-visit" }]}
                    type="provider"
                    onChange={setHomeType}
                    divClass="mb-4"
                    value={homeType}
                    formClass={"form-select"}
                  />
                ) : (
                  ""
                )}
                {(homeType === "nursing" && active === "homevisit") ||
                active === "lab-scan" ? (
                  <SelectBox
                    placeholder={t("providers")}
                    items={
                      active === "homevisit" ? nursingSuppliers : LabSuppliers
                    }
                    type="supplier"
                    onChange={setSelectedProvider}
                    divClass="mb-4"
                    value={selectedProvider}
                    valueIsId={selectedProvider}
                    formClass={"form-select"}
                  />
                ) : active === "pharmacy" ? (
                  <SelectBox
                    placeholder={t("categories")}
                    items={categories}
                    type="supplier"
                    onChange={setSelectedCategory}
                    divClass="mb-4"
                    value={selectedCategory}
                    valueIsId={true}
                    formClass={"form-select"}
                  />
                ) : (
                  <SelectBox
                    placeholder={t("landing.searchspecialty")}
                    items={specialties}
                    type="specialty"
                    onChange={setSelectedSpecialty}
                    divClass="mb-4"
                    value={selectedSpecialty}
                    formClass={"form-select"}
                  />
                )}
                {active === "doctor" ? (
                  <SelectBox
                    placeholder={t("landing.searchregion")}
                    items={regions}
                    type="region"
                    onChange={setSelectedRegion}
                    divClass="mb-4"
                    value={selectedRegion}
                    formClass={"form-select"}
                  />
                ) : (
                  ""
                )}
                <TextInput
                  property1="empty"
                  className="text-input width100per search-input none-mobile-view"
                  placeholder={t("landing.searchname")}
                  onChange={setSearchName}
                  divClass="mb-3"
                />
                <div className="search-button" onClick={() => search()}>
                  <img  loading="lazy"
                    className="search-icon"
                    alt=""
                    src="/img/search@2x.png"
                  />
                  <div className="search2">{t("header.search")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column slogan-logo none-web-view">
            <img  loading="lazy"
              src="/img/Logo&Slogan (Colored).png"
              alt="slogan"
              className=""
            />
            <div className="slogan-text">{t("doxx.slogan")}</div>
          </div>
        </div>
        <div className="askdoctortips">
          <div className="ask-a-doctor">
            <b className="ask-a-doctor1">{t("landing.askdoctor")}</b>
            <div className="textbutton">
              <div className="submit-a-medical-container">
                <p className="submit-a-medical">
                  {t("landing.submitquestion")}
                </p>
                <p className="submit-a-medical">{t("landing.andoneof")}</p>
                <p className="submit-a-medical">{t("landing.willanswer")}</p>
                <p className="submit-a-medical">{t("landing.astheycan")}</p>
              </div>
              <div
                className="submit-question-button pointer"
                onClick={() =>
                  navigate("/dashboard", {
                    state: {
                      selected: "medical",
                    },
                  })
                }
              >
                <b className="submit-question">{t("landing.submitquestion")}</b>
              </div>
            </div>
            <img  loading="lazy"
              className="doctor-image-icon"
              alt=""
              src="/img/doctor-image@2x.png"
            />
          </div>
          <div className="medical-tip">
            <img  loading="lazy"
              className="medical-tip-child"
              alt=""
              src="/img/rectangle-2@2x.png"
            />
          </div>
        </div>
        <div className="slides-container">
          <Specialties />
        </div>
        <div className="slides-container">
          {offers?.length ? <Slides offers={offers} /> : ""}
        </div>
      </div>
    </>
  );
};

export default LandingPage;